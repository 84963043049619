import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import _ from "lodash"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Column = styled.div`
  flex: calc(25% - 0.5em);
  @media only screen and (max-width: 1200px) {
    flex: calc(33% - 0.5em);
  }

  @media only screen and (max-width: 576px) {
    flex: calc(50% - 0.5em);
  }

  padding: 0.25em;
`

const Neomix = () => {
  const [neomixes, setNeomixes] = useState([])
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "neomix" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(
                maxHeight: 1000
                cropFocus: CENTER
                fit: CONTAIN
                traceSVG: { color: "#4400CC" }
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setNeomixes(
      _.shuffle(
        data.allFile.edges.map(({ node }) => node.childImageSharp.fluid)
      )
    )
  }, [])
  return (
    <Layout
      prev={{ name: "함민주", link: "/함민주" }}
      next={{ name: "평론의 글", link: "/review" }}
      title={"네오-믹스"}
    >
      <SEO title="네오-믹스" />
      <p className="neomix-text md">
        17인의 작가들 자신의 트라이브를 대표하는 문구는 분해되어 다른 작가의
        트라이브를 대표하는 문구와 재조립되면서 새로운 메시지를 만들어낸다.
        이것은 새로운 네오-트라이브의 탄생이다. 사회적 거리두기 상황에서 태어난
        새로운 부족인 네오-트라이브는 여럿의 가상 공간에 빠르게 모이고 흩어지는
        습성을 가지고 있다. 네오-믹스를 통해 이런 재빠른 순간들을 포착하고
        표현해내고자 했다. 김양진의 “모든 것은 랜선으로”의 “모든 것은”과
        박한솔의 “마스크도 좋지만 우리 집 침대가 더 좋아요”의 “침대가 더
        좋아요”는 합쳐서 “모든 것은 침대가 더 좋아요”를 만들어낸다.
        “마스크(박한솔) 미착용시(이주현) 거리두기(채희준)”, “모여봐요(하형원)
        방구석(정지혜)”, “집콕 중(김리원) 화상미팅(하형원) 참가(신건모)”….
        각자의 트라이브를 나타내는 특색 있는 활자들이 모여 새로운 문장을, 새로운
        시각적 이미지로 만들어낸다.
      </p>

      <br />

      <div className="iframe-container sm">
        <iframe
          src="https://www.youtube.com/embed/rTzwhrEup3U"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      <p className="video-caption caption sm">
        네오-믹스를 통해 네오-트라이브를 부여하는 과정
      </p>

      <br />

      <p>
        이를 우리는 네오-믹스라 하고, 네오-믹스에서는 관람객들에게 해당하는
        네오-트라이브를 랜덤하게 부여한다. 네오-믹스를 통해 새롭게 탄생한
        네오-트라이브를 관람객들 각각에게 짝지어 주는 것이다. 관람객들로 하여금
        새로운 네오-트라이브의 일원으로서 전시에 참여할 수 있게 의도한다.
        네오-믹스에서 새롭게 제시하는 네오-트라이브는 다음과 같다.
      </p>

      <br />

      <Container>
        {neomixes.map((src, ind) => {
          const element = (
            <Column>
              <Img
                fluid={src}
                style={{
                  width: "100%",
                  maxHeight: "inherit",
                  objectFit: "contain",
                }}
                imgStyle={{
                  width: "100%",
                  maxHeight: "inherit",
                  objectFit: "contain",
                }}
              />
            </Column>
          )
          return element
        })}
      </Container>
    </Layout>
  )
}

export default Neomix
